@import "lib";

#more-results {
  display: inline-block;
  margin: 1.5rem auto 0;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  opacity: .75;

  &.disabled {
    cursor: wait;
  }

  &:hover:not(.disabled) {
    cursor: pointer;
    opacity: 1;
  }

  p {
    font-size: 1rem;
    font-family: $font-family-light;
  }
}
