@import '../../common/scss/lib';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/utilities/spacing';
@import '~bootstrap/scss/utilities/text';
@import '~bootstrap/scss/utilities/sizing';
@import "../../common/scss/see-more-results";

main {
    @extend .px-lg-5;
}

.list-designers-container {
    .photo-list {
        @extend .no-gutters;
        @extend .p-md-4;
        @extend .pt-2;

        @include media-breakpoint-up(md) {
            background-color: $lavender;
        }

        @include media-breakpoint-down(sm) {
            margin-left: -.25rem;
            margin-right: -.25rem;
        }

        .photo-card {
            @extend .col-6;
            @extend .col-md-4;
            @extend .p-1;
            @extend .p-md-3;
            background-color: transparent;
            .photo-badges {
                @include badges;

                .photo-badge {
                    @include badge;
                }
            }
        }
        @include media-breakpoint-up(lg) {
            .photo-card {
                flex: 0 0 25% !important;
                max-width: 25% !important;
            }
            .photo-card:nth-last-child(n+5),
            .photo-card:nth-last-child(n+5) ~ .photo-card {
                flex: 0 0 20% !important;
                max-width: 20% !important;
            }
        }
    }
}
